import IconBlouse from "assets/icon-blouse.svg";
import IconCalendar from "assets/icon-calendar.svg";
import IconDisplays from "assets/icon-displays.svg";
import IconInjury from "assets/icon-injury.svg";
import IconLeaf from "assets/icon-leaf.svg";
import IconSend from "assets/icon-send.svg";
import IconStopwatch from "assets/icon-stopwatch.svg";
import IconUnion from "assets/icon-union.svg";
import IconWorkProgram from "assets/icon-work-program.svg";
import CalculatorAdvice from "components/calculatorAdvice/calculatorAdvice";
import Footer from "components/footer/footer";
import Button from "components/material/button/button";
import SendCalculationEmail from "components/sendCalculationEmail/sendCalculationEmail";
import SubscribeToWaitingList from "components/subscribeToWaitingList/subscribeToWaitingList";
import UnitPrice from "components/unitPrice/unitPrice";
import ZipCode from "components/zipCode/zipCode";
import { DataLayerEvents, DataLayerNames } from "constants/dataLayerEvent";
import { StorageItems } from "constants/storageItems";
import { UnitPrices } from "constants/unitPrice";
import CalculatorType from "enums/calculatorType";
import IconPositionTypes from "enums/iconPositionType";
import TranslationMapper from "i18n/mapper";
import { IRegistrationWizardCalculatorProps } from "interfaces/IRegistrationWizard";
import CalculatorProvider from "providers/calculatorProvider";
import DimensionsProvider from "providers/dimensionProvider";
import LanguageProvider from "providers/languageProvider";
import LocalStorageProvider from "providers/localStorageProvider";
import { RoutingPath } from "providers/routingProvider";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  setUpdateDataCalculator,
  setUpdateDataCalculatorValid,
  setUpdateModalCalculatorAdviceShown,
  setUpdateModalSendCalculationEmailShown,
} from "store/actions/registrationActions";
import { RootState } from "store/reducers/rootReducer";
import { ScriptUtil } from "utils/scriptUtil";

import { SiteUrls } from "constants/siteUrl";
import { CleaningFrequency } from "./enums/cleaningFrequency";
import { ICalculatorDispatchProps, ICalculatorProps, ICalculatorStateProps } from "./interfaces/ICalculatorProps";
import { ICalculatorState } from "./interfaces/ICalculatorState";

class Calculator extends Component<ICalculatorProps, ICalculatorState> {
  private _timer: NodeJS.Timeout;
  private readonly _timerDelay: number = 300;
  private _selectedWeeklyIroningHours: number = CalculatorProvider.durationIroningDefault;
  private isDataLayerCleaningFrequencyUpdated = false;
  private isDataLayerCleaningHoursUpdated = false;
  private isDataLayerIncludeIroningUpdated = false;

  public constructor(props: ICalculatorProps) {
    super(props);

    this.state = this.defaultState();

    this.toStep = this.toStep.bind(this);
    this.handleSendCalculationEmailShow = this.handleSendCalculationEmailShow.bind(this);
  }

  public componentDidUpdate(prevProps: Readonly<ICalculatorProps>): void {
    // Set result of selectedWeeklyHours when updated. For example when component CalculatorAdvice is applied
    if (prevProps.calculation.selectedWeeklyHours !== this.props.calculation.selectedWeeklyHours) {
      this.setState((oldState) => {
        return {
          calculation: {
            ...oldState.calculation,
            selectedWeeklyHours: this.props.calculation.selectedWeeklyHours,
          },
        };
      });
    }

    // GTM dataLayer
    if (prevProps.calculation.cleaningFrequency !== this.props.calculation.cleaningFrequency) {
      this.isDataLayerCleaningFrequencyUpdated = true;
    }

    if (prevProps.calculation.includeIroning !== this.props.calculation.includeIroning) {
      this.isDataLayerIncludeIroningUpdated = true;
    }

    if (prevProps.calculation.selectedWeeklyHours !== this.props.calculation.selectedWeeklyHours) {
      this.isDataLayerCleaningHoursUpdated = true;
    }

    // GTM events
    // Implemented this way because of the async nature of the calculation
    if (
      prevProps.calculationResult.costEmployee !== this.props.calculationResult.costEmployee ||
      prevProps.calculationResult.costEmployer !== this.props.calculationResult.costEmployer
    ) {
      // Frequency updated
      if (this.isDataLayerCleaningFrequencyUpdated) {
        this.isDataLayerCleaningFrequencyUpdated = false;
        ScriptUtil.dataLayerPush({
          currency: "EUR",
          coupon: this.props.calculation.discountCode,
          event: DataLayerEvents.Step1Frequency,
          frequency: CleaningFrequency[this.props.calculation.cleaningFrequency],
          step: 1,
          step_name: DataLayerNames.Step1Cleaning,
          value: this.props.calculationResult.costEmployee,
        });
      }

      //   Ironing updated
      if (this.isDataLayerIncludeIroningUpdated) {
        this.isDataLayerIncludeIroningUpdated = false;
        ScriptUtil.dataLayerPush({
          currency: "EUR",
          coupon: this.props.calculation.discountCode,
          event: DataLayerEvents.Step1CalculatorIroning,
          ironing_service: this.props.calculation.includeIroning ? "Yes" : "No",
          step: 1,
          step_name: DataLayerNames.Step1Cleaning,
          value: this.props.calculationResult.costEmployee,
        });
      }

      if (this.isDataLayerCleaningHoursUpdated) {
        // Cleaning hours update
        this.isDataLayerCleaningHoursUpdated = false;
        ScriptUtil.dataLayerPush({
          currency: "EUR",
          coupon: this.props.calculation.discountCode,
          event: DataLayerEvents.Step1CalculatorCleaningHours,
          step: 1,
          step_name: DataLayerNames.Step1Cleaning,
          time_in_hours: this.props.calculation.selectedWeeklyHours,
          time_in_minutes: this.props.calculation.selectedWeeklyHours * 60,
          value: this.props.calculationResult.costEmployee,
        });
      }
    }
    // -
  }

  private defaultState(): ICalculatorState {
    const state: ICalculatorState = {
      calculation: {
        cleaningFrequency: this.props.calculation.cleaningFrequency,
        houseType: this.props.calculation.houseType,
        houseSurface: this.props.calculation.houseSurface,
        includeIroning: this.props.calculation.includeIroning,
        selectedWeeklyHours: this.props.calculation.selectedWeeklyHours,
        selectedWeeklyIroningHours: this.props.calculation.selectedWeeklyIroningHours,
        discountCode: this.props.calculation.discountCode,
        useSelectedWeeklyHoursInCalculation: this.props.calculation.useSelectedWeeklyHoursInCalculation,
      },

      discount: {
        availableFrequencies: this.props.discount.availableFrequencies,
        calculatorType: this.props.discount.calculatorType,
        zizoCodeErrorCode: this.props.discount.zizoCodeErrorCode,
      },
      durationTotal: CalculatorProvider.durationMin,
      isCalculatorSubmitted: false,
      isValid: false,
      isFormValidated: false,
      isZipCodeValid: false,
      addressExists: false,
    };

    return state;
  }

  public toStep(path: string, search?: string): void {
    this.props.history.push({
      pathname: path,
      search,
    });
  }

  private get isDurationMax(): boolean {
    if (this.state.calculation.includeIroning) {
      return (
        this.state.calculation.selectedWeeklyHours + this.state.calculation.selectedWeeklyIroningHours >=
        CalculatorProvider.durationMax
      );
    }

    return this.state.calculation.selectedWeeklyHours === CalculatorProvider.durationMax;
  }

  private get isDurationCleaningMin(): boolean {
    return this.state.calculation.selectedWeeklyHours === CalculatorProvider.durationCleaningMin;
  }

  private get isDurationCleaningMax(): boolean {
    return this.state.calculation.selectedWeeklyHours === CalculatorProvider.durationMax || this.isDurationMax;
  }

  private get isDurationIroningMin(): boolean {
    return this.state.calculation.selectedWeeklyIroningHours === CalculatorProvider.durationIroningMin;
  }

  private get isDurationIroningMax(): boolean {
    return this.state.calculation.selectedWeeklyIroningHours === CalculatorProvider.durationMax || this.isDurationMax;
  }

  private get isIroning(): boolean {
    return this.state.calculation.includeIroning;
  }

  private get isCalculatorSubmitted(): boolean {
    return this.state.isCalculatorSubmitted;
  }

  private get data(): IRegistrationWizardCalculatorProps {
    return {
      calculation: {
        cleaningFrequency: this.state.calculation.cleaningFrequency,
        houseType: this.props.calculation.houseType,
        houseSurface: this.props.calculation.houseSurface,
        includeIroning: this.state.calculation.includeIroning,
        selectedWeeklyHours: this.state.calculation.selectedWeeklyHours,
        selectedWeeklyIroningHours: this.state.calculation.selectedWeeklyIroningHours,
        discountCode: this.props.calculation.discountCode,
        useSelectedWeeklyHoursInCalculation: this.props.calculation.useSelectedWeeklyHoursInCalculation,
      },
      discount: {
        availableFrequencies: this.props.discount.availableFrequencies,
        calculatorType: this.props.discount.calculatorType,
        zizoCodeErrorCode: this.props.discount.zizoCodeErrorCode,
      },
      isValid: this.state.isValid,
      isZipCodeValid: this.props.isZipCodeValid,
      addressExists: this.props.addressExists,
    };
  }

  private get isFormValidated(): boolean {
    return this.state.isFormValidated;
  }

  private get duration(): number {
    return this.state.calculation.selectedWeeklyHours;
  }

  private get isDiscountCodePercentageEnabled(): boolean {
    return this.props.discount.calculatorType === CalculatorType.Default;
  }

  private get isDiscountCodeFixedEnabled(): boolean {
    return this.props.discount.calculatorType === CalculatorType.Fixed;
  }

  private get isFrequencyColumn(): boolean {
    return this.props.discount.availableFrequencies.length > 2;
  }

  private handleFrequency(cleaningFrequency: CleaningFrequency): void {
    const state = { ...this.state };
    state.calculation.cleaningFrequency = cleaningFrequency;
    this.setState(state, this.handleSaveSettings);
  }

  private handleDurationCleaning(increase: boolean): void {
    clearTimeout(this._timer);
    let selectedWeeklyHours = this.state.calculation.selectedWeeklyHours;
    if (increase) {
      selectedWeeklyHours = selectedWeeklyHours + CalculatorProvider.step;
    } else {
      selectedWeeklyHours = selectedWeeklyHours - CalculatorProvider.step;
    }

    if (selectedWeeklyHours < CalculatorProvider.durationCleaningMin) {
      // min duration
      selectedWeeklyHours = CalculatorProvider.durationCleaningMin;
    } else if (selectedWeeklyHours > CalculatorProvider.durationMax) {
      // max duration
      selectedWeeklyHours = CalculatorProvider.durationMax;
    }

    const state = { ...this.state };
    state.calculation.selectedWeeklyHours = selectedWeeklyHours;
    this.setState(state, () => {
      this._timer = setTimeout(() => {
        this.handleSaveSettings();
      }, this._timerDelay);
    });
  }

  private handleIroning(includeIroning: boolean): void {
    clearTimeout(this._timer);

    // if ironing is deselected, then save selectedWeeklyIroningHours in SessionStorage
    if (!includeIroning) {
      this._selectedWeeklyIroningHours = this.data.calculation.selectedWeeklyIroningHours;
    }
    // -

    const state = { ...this.state };

    // set default value when ironing is selected
    if (!includeIroning) {
      state.calculation.selectedWeeklyIroningHours = 0;
    } else if (includeIroning && state.calculation.selectedWeeklyIroningHours === 0) {
      state.calculation.selectedWeeklyIroningHours = this._selectedWeeklyIroningHours;
    } else {
      state.calculation.selectedWeeklyIroningHours = CalculatorProvider.durationIroningDefault;
    }
    // -

    // Check when ironing is select total duration not exceeds durationMax
    let selectedWeeklyHours = this.state.calculation.selectedWeeklyHours;
    if (selectedWeeklyHours + this.state.calculation.selectedWeeklyIroningHours >= CalculatorProvider.durationMax) {
      selectedWeeklyHours = CalculatorProvider.durationMax - this.state.calculation.selectedWeeklyIroningHours;
    }
    // -

    state.calculation.selectedWeeklyHours = selectedWeeklyHours;
    state.calculation.includeIroning = includeIroning;

    this.setState(state, () => {
      this._timer = setTimeout(() => {
        this.handleSaveSettings();
      }, this._timerDelay);
    });
  }

  private handleDurationIroning(increase: boolean): void {
    clearTimeout(this._timer);
    let selectedWeeklyIroningHours = this.state.calculation.selectedWeeklyIroningHours;

    if (increase) {
      selectedWeeklyIroningHours = selectedWeeklyIroningHours + CalculatorProvider.step;
    } else {
      selectedWeeklyIroningHours = selectedWeeklyIroningHours - CalculatorProvider.step;
    }

    if (selectedWeeklyIroningHours < CalculatorProvider.durationIroningMin) {
      // min duration
      selectedWeeklyIroningHours = CalculatorProvider.durationIroningMin;
    } else if (selectedWeeklyIroningHours > CalculatorProvider.durationMax) {
      // max duration
      selectedWeeklyIroningHours = CalculatorProvider.durationMax;
    }

    const state = { ...this.state };
    state.calculation.selectedWeeklyIroningHours = selectedWeeklyIroningHours;
    this.setState(state, () => {
      this._timer = setTimeout(() => {
        this._selectedWeeklyIroningHours = this.data.calculation.selectedWeeklyIroningHours;
        this.handleSaveSettings();
      }, this._timerDelay);
    });
  }

  private handleCalculationAdviceShow(): void {
    this.props.updateModalCalculatorAdviceShown(true);
  }

  private handleSendCalculationEmailShow(): void {
    this.props.updateModalSendCalculationEmailShown(true);
  }

  private handleSaveSettings(): void {
    this.props.updateDataCalculator(this.data);
  }

  private scrollToZipCode(): void {
    // Scroll to ZipCode header if not filled in or not zipCode not yet checked
    // Mobile only
    if (!DimensionsProvider.isBreakpointUpLG) {
      const zipcodeHeader = document.getElementById("zipcode-header");
      if (zipcodeHeader) {
        zipcodeHeader.scrollIntoView();
      }
    }
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private handleSubmit(e: any): void {
    this.props.updateDataCalculatorValid(false);
    const form = e.target;
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isFormValidated: true });

    if (form.checkValidity()) {
      this.setState({ isCalculatorSubmitted: true });
      if (this.props.isZipCodeValid && this.props.addressExists) {
        this.setState({ isValid: true }, () => {
          const calculatorLocalStorageData = { ...this.data };
          delete calculatorLocalStorageData.calculation.discountCode;
          LocalStorageProvider.set(StorageItems.Calculator, calculatorLocalStorageData);
          this.props.updateDataCalculatorValid(true);
          this.toStep(RoutingPath.personalInformation);

          ScriptUtil.dataLayerPush({
            active_on_address: "Yes",
            currency: "EUR",
            coupon: this.props.calculation.discountCode,
            event: DataLayerEvents.Step1CalculatorSubmitted,
            frequency: CleaningFrequency[this.state.calculation.cleaningFrequency],
            ironing_service: this.state.calculation.includeIroning ? "Yes" : "No",
            step: 1,
            step_name: DataLayerNames.Step1Cleaning,
            time_in_hours: this.props.calculation.selectedWeeklyHours,
            time_in_minutes: this.props.calculation.selectedWeeklyHours * 60,
            value: this.props.calculationResult.costEmployee,
          });
        });
      } else {
        this.scrollToZipCode();
      }
    } else {
      this.scrollToZipCode();
    }
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  public renderUSPs(): JSX.Element {
    const USPs = [
      {
        key: 0,
        icon: IconUnion,
        text: LanguageProvider.t(TranslationMapper.calculator.usp.text1),
      },
      {
        key: 1,
        icon: IconDisplays,
        text: LanguageProvider.t(TranslationMapper.calculator.usp.text2),
      },
      {
        key: 2,
        icon: IconLeaf,
        text: LanguageProvider.t(TranslationMapper.calculator.usp.text3),
      },
      {
        key: 3,
        icon: IconInjury,
        text: LanguageProvider.t(TranslationMapper.calculator.usp.text4),
      },
    ];
    return (
      <div className="card card--gray mb-lg-md small calculation">
        <div className="card-body">
          <div className="mb-4 fw-bold text-primary">{LanguageProvider.t(TranslationMapper.calculator.usp.label)}</div>
          <div className="row">
            {USPs.map((usp) => {
              return (
                <label className="col-12 col-lg-6 form-check-label card--gray__label" key={usp.key}>
                  <div className="d-flex align-items-center align-items-lg-start">
                    <div className="d-flex justify-content-center card--gray__label__img">
                      <img src={usp.icon} className="icon icon__md" alt="" />
                    </div>
                    <div>{usp.text}</div>
                  </div>
                </label>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  public renderFrequency(): JSX.Element {
    let frequencies = this.props.discount.availableFrequencies;
    if (frequencies.length === 0) {
      frequencies = [0, 1];
    }

    return (
      <div className={`row mb-lg-md frequencies${this.isFrequencyColumn ? " frequencies--xs-column" : ""}`}>
        {frequencies.includes(0) && (
          <div className={`${this.isFrequencyColumn ? "col-12 col-sm" : "col"}`}>
            <div className="form-check form-check-inline d-flex align-items-center w-100">
              <input
                className="form-check-input"
                type="radio"
                name="frequency"
                id="frequency-weekly"
                required
                checked={this.state.calculation.cleaningFrequency === CleaningFrequency.Weekly}
                onChange={(): void => this.handleFrequency(CleaningFrequency.Weekly)}
              />
              <label className="form-check-label" htmlFor="frequency-weekly">
                {LanguageProvider.t(TranslationMapper.date.weekly)}
              </label>
            </div>
          </div>
        )}
        {frequencies.includes(1) && (
          <div className={`${this.isFrequencyColumn ? "col-12 col-sm" : "col"}`}>
            <div className="form-check form-check-inline d-flex align-items-center w-100">
              <input
                className="form-check-input"
                type="radio"
                name="frequency"
                id="frequency-biweekly"
                checked={this.state.calculation.cleaningFrequency === CleaningFrequency.Biweekly}
                onChange={(): void => this.handleFrequency(CleaningFrequency.Biweekly)}
              />
              <label className="form-check-label" htmlFor="frequency-biweekly">
                {LanguageProvider.t(TranslationMapper.date.biweekly)}
              </label>
            </div>
          </div>
        )}
        {frequencies.includes(2) && (
          <div className={`${this.isFrequencyColumn ? "col-12 col-sm" : "col"}`}>
            <div className="form-check form-check-inline d-flex align-items-center w-100">
              <input
                className="form-check-input"
                type="radio"
                name="frequency"
                id="frequency-four-weekly"
                checked={this.state.calculation.cleaningFrequency === CleaningFrequency.FourWeekly}
                onChange={(): void => this.handleFrequency(CleaningFrequency.FourWeekly)}
              />
              <label className="form-check-label" htmlFor="frequency-four-weekly">
                {LanguageProvider.t(TranslationMapper.date.four_weekly)}
              </label>
            </div>
          </div>
        )}
      </div>
    );
  }

  public renderCleaning(): JSX.Element {
    return (
      <div className="row mb-lg-md">
        <label className="col-12 col-lg-6 form-check-label d-flex justify-content-start mb-md-sm">
          <img src={IconStopwatch} className="icon icon__lg" alt="" />
          <div className="ps-2">
            <p className="mb-0 d-flex align-items-center">
              {LanguageProvider.t(TranslationMapper.calculator.calculator.header)}
            </p>

            <Button
              className="btn-link btn-sm fw-normal p-0"
              label={LanguageProvider.t(TranslationMapper.calculator.calculator.text)}
              onClick={(): void => this.handleCalculationAdviceShow()}
            />
          </div>
        </label>

        <div className="col-12 col-lg-6">
          <div className="d-flex justify-content-between align-items-center amount amount--min-width">
            <div
              className={`btn btn-outline-primary btn-outline-primary--circle${
                this.isDurationCleaningMin ? " disabled" : ""
              }`}
              onClick={(): void => this.handleDurationCleaning(false)}
            >
              -
            </div>
            <div className="h1">
              {this.state.calculation.selectedWeeklyHours >= 1 && (
                <>
                  {CalculatorProvider.getHours(this.duration)}
                  <small>{LanguageProvider.t(TranslationMapper.date.time_unit.abbr.hour)}</small>{" "}
                </>
              )}
              {CalculatorProvider.getMinutes(this.duration) !== "0" && (
                <>
                  {CalculatorProvider.getMinutes(this.duration)}
                  <small>{LanguageProvider.t(TranslationMapper.date.time_unit.abbr.minute)}</small>
                </>
              )}
            </div>
            <div
              className={`btn btn-outline-primary btn-outline-primary--circle${
                this.isDurationCleaningMax ? " disabled" : ""
              }`}
              onClick={(): void => this.handleDurationCleaning(true)}
            >
              +
            </div>
          </div>
        </div>
      </div>
    );
  }

  public renderIroning(): JSX.Element {
    return (
      <>
        <h2>{LanguageProvider.t(TranslationMapper.calculator.iron.header)}</h2>
        <div className="mb-lg-md">
          <div className="d-lg-flex justify-content-between align-items-center">
            <label className="form-check-label d-flex justify-content-start mb-3 mb-lg-0 " htmlFor="inlineRadio1">
              <img src={IconBlouse} className="icon icon__lg" alt="" />
              <p className="ps-2 mb-0 d-flex align-items-center">
                {LanguageProvider.t(TranslationMapper.calculator.iron.text)}
              </p>
            </label>
            <div className="d-flex">
              <div className="form-check form-check-inline d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="radio"
                  name="iron"
                  id="iron-no"
                  value="false"
                  required
                  checked={this.state.calculation.includeIroning === false}
                  onChange={(): void => this.handleIroning(false)}
                />
                <label className="form-check-label" htmlFor="iron-no">
                  {LanguageProvider.t(TranslationMapper.global.no)}
                </label>
              </div>
              <div className="form-check form-check-inline d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="radio"
                  name="iron"
                  id="iron-yes"
                  checked={this.state.calculation.includeIroning === true}
                  onChange={(): void => this.handleIroning(true)}
                />
                <label className="form-check-label" htmlFor="iron-yes">
                  {LanguageProvider.t(TranslationMapper.global.yes)}
                </label>
              </div>
            </div>
          </div>

          {this.isIroning && (
            <div className="row mt-3 pt-3">
              <label className="col-12 col-lg-6 form-check-label d-flex justify-content-start mb-md-sm">
                <img src={IconStopwatch} className="icon icon__lg" alt="" />
                <div className="ps-2">
                  <p className="mb-0 d-flex align-items-center">
                    {LanguageProvider.t(TranslationMapper.calculator.iron.text2)}
                  </p>
                </div>
              </label>

              <div className="col-12 col-lg-6">
                <div className="d-flex justify-content-between align-items-center amount amount--min-width">
                  <div
                    className={`btn btn-outline-primary btn-outline-primary--circle${
                      this.isDurationIroningMin ? " disabled" : ""
                    }`}
                    onClick={(): void => this.handleDurationIroning(false)}
                  >
                    -
                  </div>
                  <div className="h1">
                    {this.state.calculation.selectedWeeklyIroningHours >= 1 && (
                      <>
                        {CalculatorProvider.getHours(this.state.calculation.selectedWeeklyIroningHours || 0)}
                        <small>{LanguageProvider.t(TranslationMapper.date.time_unit.abbr.hour)}</small>{" "}
                      </>
                    )}
                    {CalculatorProvider.getMinutes(this.state.calculation.selectedWeeklyIroningHours || 0) !== "0" && (
                      <>
                        {CalculatorProvider.getMinutes(this.state.calculation.selectedWeeklyIroningHours || 0)}
                        <small>{LanguageProvider.t(TranslationMapper.date.time_unit.abbr.minute)}</small>
                      </>
                    )}
                  </div>
                  <div
                    className={`btn btn-outline-primary btn-outline-primary--circle${
                      this.isDurationIroningMax ? " disabled" : ""
                    }`}
                    onClick={(): void => this.handleDurationIroning(true)}
                  >
                    +
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }

  public render(): JSX.Element {
    // back or save calculation button (depends on discount code availability)
    let contentStartButton = {};
    if (this.isDiscountCodeFixedEnabled || this.isDiscountCodePercentageEnabled) {
      contentStartButton = {
        onClick: () => this.toStep(RoutingPath.discount, `?code=${this.props.calculation.discountCode}`),
      };
    } else {
      contentStartButton = {
        className: "btn-link btn-sm ps-0 btn__icon__sm",
        icon: IconSend,
        iconPosition: IconPositionTypes.End,
        label: LanguageProvider.t(TranslationMapper.calculator.save_calculation),
        onClick: (): void => this.handleSendCalculationEmailShow(),
      };
    }

    return (
      <>
        <div className="mb-lg-lg">
          <h1 className="mb-3">{LanguageProvider.t(TranslationMapper.calculator.title)}</h1>
          {!this.isDiscountCodeFixedEnabled && (
            <p className="mb-lg">
              {LanguageProvider.t(TranslationMapper.calculator.text.text1)}{" "}
              <UnitPrice type={UnitPrices.CleanerHourlyRate} />{" "}
              {LanguageProvider.t(TranslationMapper.calculator.text.text2)}
            </p>
          )}
          <a
            href={`${SiteUrls.WorkProgram}`}
            className="btn btn-link btn-sm ps-0 btn__icon__sm"
            target="_blank"
            rel="noreferrer"
          >
            {LanguageProvider.t(TranslationMapper.calculator.text.button.label)}
            <img
              src={IconWorkProgram}
              alt={LanguageProvider.t(TranslationMapper.calculator.text.button.label)}
              className="ms-2"
            />
          </a>
        </div>

        {this.renderUSPs()}

        <h2 className="mb-3">{LanguageProvider.t(TranslationMapper.calculator.amount.header)}</h2>
        <p className="mb-lg-md">{LanguageProvider.t(TranslationMapper.calculator.amount.text)}</p>

        <label className="form-check-label d-flex justify-content-start mb-3">
          <img src={IconCalendar} className="icon icon__lg" alt="" />
          <p className="ps-2 mb-0 d-flex align-items-center">
            {LanguageProvider.t(TranslationMapper.calculator.amount.frequency)}
          </p>
        </label>
        <form
          noValidate
          onSubmit={(e): void => this.handleSubmit(e)}
          className={`needs-validation${this.isFormValidated ? " was-validated" : ""}`}
          id="form-calculator"
        >
          {this.renderFrequency()}
          {!this.isDiscountCodeFixedEnabled && this.renderCleaning()}
          {!this.isDiscountCodeFixedEnabled && (
            <>
              <hr />
              {this.renderIroning()}
            </>
          )}
          <hr />
          <ZipCode isCalculatorSubmitted={this.isCalculatorSubmitted} />
          <Footer
            className={`${
              this.isDiscountCodeFixedEnabled || this.isDiscountCodePercentageEnabled ? "" : "justify-content-center"
            }`}
            contentStartButton={contentStartButton}
            contentEndButton={{
              label: LanguageProvider.t(TranslationMapper.calculator.next),
              type: "submit",
            }}
          />
        </form>
        <SendCalculationEmail />
        <SubscribeToWaitingList />
        <CalculatorAdvice />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ICalculatorStateProps => ({
  calculation: state.registrationState.calculator.calculation,
  calculationResult: state.registrationState.calculation,
  discount: state.registrationState.calculator.discount,
  isValid: state.registrationState.calculator.isValid,
  isZipCodeValid: state.registrationState.zipCode.isZipCodeValid,
  addressExists: state.registrationState.zipCode.addressExists,
});

const mapDispatchToProps: ICalculatorDispatchProps = {
  updateDataCalculator: setUpdateDataCalculator,
  updateDataCalculatorValid: setUpdateDataCalculatorValid,
  updateModalCalculatorAdviceShown: setUpdateModalCalculatorAdviceShown,
  updateModalSendCalculationEmailShown: setUpdateModalSendCalculationEmailShown,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Calculator));
